'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.tasks.controller:TasksCtrl

 # @description

###
class TasksCtrl
  ### @ngInject ###
  constructor: ->
    @ctrlName = 'TasksCtrl'

angular
  .module('mundoAdmin.tasks')
  .controller 'TasksCtrl', TasksCtrl
